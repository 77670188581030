import React, { Component } from "react";
import "./Timer.css";

class CountDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      time_up: ""
    };
    this.deadline = new Date("Oct 31, 2023 23:59:00").getTime();
  }

  componentDidMount() {
    this.count(); // Initial call to update the timer immediately
    this.x = setInterval(this.count, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.x); // Clean up the interval when the component unmounts
  }

  count = () => {
    const now = new Date().getTime();
    const t = this.deadline - now;

    if (t <= 0) {
      clearInterval(this.x);
      this.setState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        time_up: "TIME IS UP"
      });
    } else {
      const days = Math.floor(t / (1000 * 60 * 60 * 24));
      const hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((t % (1000 * 60)) / 1000);

      this.setState({ days, hours, minutes, seconds });
    }
  };

  render() {
    const { days, hours, minutes, seconds, time_up } = this.state;

    return (
      <div id="countdown">
        <div className="col-4">
          <div className="box">
            <p id="day">{days}</p>
            <span className="text">Days</span>
          </div>
        </div>
        <div className="col-4">
          <div className="box">
            <p id="hour">{hours}</p>
            <span className="text">Hours</span>
          </div>
        </div>
        <div className="col-4">
          <div className="box">
            <p id="minute">{minutes}</p>
            <span className="text">Minutes</span>
          </div>
        </div>
        <div className="col-4">
          <div className="box">
            <p id="second">{seconds}</p>
            <span className="text">Seconds</span>
          </div>
        </div>
        <p>{time_up}</p>
      </div>
    );
  }
}

export default CountDown;
